import { useMutation } from 'urql';
import { SubscribeToNewsletterDocument } from '@packages/gql/generated/shopping/SubscribeToNewsletterDocument';
import { useI18n } from '@packages/shared';
import { useFormDeviceType } from '../GeneralForm/helpers/useFormDeviceType';
import type { FormValues } from '../NewsletterSubscribeForm/types';

/* GraphQL */ `
  mutation SubscribeToNewsletter(
    $email: String!
    $deviceType: String!
    $language: String!
    $areaKey: String!
    $salutation: String
    $firstname: String
    $lastname: String
    $contextUri: String
  ) {
    subscribeNewsletterRecipient(
      subscribeRequest: {
        emailAddress: $email
        deviceType: $deviceType
        locale: $language
        areaKey: $areaKey
        salutation: $salutation
        firstname: $firstname
        lastname: $lastname
        contextUri: $contextUri
      }
    ) {
      ... on SubscribeResponseData {
        success
        alreadySubscribed
      }
      ... on ErrorResponse {
        errors
      }
    }
  }
`;

export const useSubscribeNewsletterGqlMutation = () => {
  const [, executeMutation] = useMutation(SubscribeToNewsletterDocument);
  const deviceType = useFormDeviceType();
  const { language } = useI18n();
  return ({
    formData,
    areaKey,
    contextUri,
  }: {
    formData: FormValues;
    areaKey: string;
    contextUri: string;
  }) => executeMutation({ areaKey, deviceType, language, contextUri, ...formData });
};
